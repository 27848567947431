<template>
  <div class="col-sm-4 mb-2">
    <div class="card">
      <div class="text-center pt-3" @click="onClickItem(product.id)">
        <img class="card-img-top" :src="$filters.image(product.image, '350x')">
      </div>
      <div class="card-body text-center px-1" @click="onClickItem(product.id)">
        <p class="card-title font-weight-bold" @click="onClickItem(product.id)">
          {{ product.name }}
        </p>
        <div class="text-center description" v-html="product.description" />
      </div>
      <p class="text-center text-danger price-size">
        <span v-if="product.discount > 0" class="badge badge-danger">-{{ product.discount }}%</span>
        <span class="option__price option__price--discount">{{ $filters.currency(discount) }}</span>
        <span v-if="product.discount > 0" class="option__price option__price--old">{{ $filters.currency(product.price) }}</span>
      </p>
      <div class="card-footer bg-primary text-white text-center pointer" @click="onClickItem(product.id)">
        Mehr erfahren
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Slugify from 'underscore.string/slugify'
export default {
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const discount = computed(() => {
      const discount = Number.parseFloat(props.product.discount) / 100 * props.product.price
      let totalDiscount = 0
      totalDiscount = props.product.price - discount
      return totalDiscount
    })
    const productUrl = computed(() => {
      return Slugify(props.product.name)
    })

    function onClickItem (id) {
      store.dispatch('product/updateProductId', { id: id })
      router.push({ name: 'product', params: { product: productUrl.value } })
    }
    return {
      discount,
      onClickItem
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  .card-title {
    font-size: 18px;
  }
  .description {
    font-size: 14px;
  }
  height: 100%;
  cursor: pointer;
}
</style>
